<template>
  <link href="https://fonts.cdnfonts.com/css/barlow" rel="stylesheet" />
  <div class="hello">
    <img class="icon main-icon" src="@/assets/main-icon.svg" alt="" />

    <!-- Widget JavaScript bundle -->
    
    <!-- Search widget element is not visible by default -->
    <!--gen-search-widget configId="ccf91ab8-abb3-4e45-85cd-3f5b5a3d0882" triggerId="searchWidgetTrigger">
    </gen-search-widget>
    <input placeholder="Search here" id="searchWidgetTrigger" /-->
    <!-- Element that opens the widget on click. It does not have to be an input -->

    
    <div class="main-chatbox">
      <input
        placeholder="Digite uma pergunta ou comando"
        type="text"
        class="search-input"
        @keyup.enter="onEnter"
        v-model="textBox"
      />
      <img @click="onEnter" class="icon search-icon" src="@/assets/magnefying-glass.svg" alt="" />
    </div>
    
    
    <div class="chatbox-wrapper">
      <div id="chatbox" class="main-chatmessage">
        <div v-for="(msg, index) in msgs" :key="index">
          <div align="left" class="box user-box">
            <img class="icon user-icon" src="" alt="" />
            <div class="user-text">
              <span> {{ msg.user }}</span>
            </div>
          </div>
          <div
            v-for="(document, index) in msg.bot.documents"
            :key="index"
            align="left"
            class="box bot-box"
          >
            <div class="user-icon-wrapper">
              <img v-if="index === 0" class="icon chat-icon" src="" alt="" />
              <img v-if="index === 1" class="icon chat-icon" src="" alt="" />
              <img v-if="index === 2" class="icon chat-icon" src="" alt="" />
              <img v-if="index === 3" class="icon chat-icon" src="" alt="" />
              <img v-if="index === 4" class="icon chat-icon" src="" alt="" />
            </div>
            <div>
              <span>
                DOCUMENTO: <span class="document-name">{{ document.name }}</span
                ><br /><br />
                RESUMO:{{ document.abstract }}<br /><br />
                ACESSE NA ÍNTEGRA:
                <i @click="redirect(document.link)">{{ document.link }}</i
                ><br /><br /><br />
                Tags:<br />
                <div v-for="(tag, index) in document.tags" :key="index">
                  <br /><span class="tags">{{ tag }}</span
                  ><br /><br />
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      botAnswer: "Justo",
      msgs: [],
      textBox: "",
      response: [],
    };
  },
  computed: {},
  methods: {
    computedSrc(index) {
      return "@/assets/chat-icon" + index + ".svg";
    },

    redirect(link) {
      window.open(link, "_blank");
    },
    onEnter() {
      //https://backend-u3vm4h6pda-uc.a.run.app/query user_query
      const axios = require("axios");
      axios
        .post(
          "https://raizen-be-jckt54ahga-uc.a.run.app/query",
          {
            user_query: this.textBox,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          this.response = response.data;

          this.msgs = [
            {
              bot: {
                documents: [],
              },
              user: this.textBox,
            },
            ...this.msgs,
          ];

          let flatResponse = JSON.parse(JSON.stringify(this.response))
          flatResponse.forEach((element) => {
            let document = {
              name: "",
              abstract:
                "",
              link: "",
              tags: [""],
            };
            console.log(element)
            document.name = element["file"];
            document.link = element["path"];
            document.abstract = element["summary"];
            let clean_tags;
            if (element["tags"]) {
              if (element["tags"].includes(":")) {
                clean_tags = element["tags"].split(":")[1];
              } else {
                clean_tags = element["tags"];
              }
              if (clean_tags.includes("\n")) {
                document.tags = clean_tags.split("\n");
              }
            }

            this.msgs[0].bot.documents.push(document);
          });
          this.textBox = "";
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
  font-family: "barlow";
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

i {
  cursor: pointer;
  color: #4ac0de;
}

.hello {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: #82008f;
  overflow: hidden;
}

.main-chatbox {
  position: absolute;
  top: 18vh;
  left: 5vw;
  width: 90vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-radius: 24px;
}

.chatbox-wrapper {
  position: absolute;
  top: 30vh;
  left: 5vw;
  width: 90vw;
  height: 68vh;
  background-color: white;
  border-radius: 24px;
}

.main-chatmessage {
  width: 88.5vw;
  margin-top: 2vh;
  max-height: 65vh;
  overflow-y: scroll;
  background-color: white;
  border-radius: 24px;
}

.tags {
  padding: 10px;
  margin: 5px 10px;
  border-radius: 24px;
  background-color: #82008f;
  color: white;
}

.box {
  background-color: #f5f5f5;
  margin: 20px;
  padding: 2vw;
  border-radius: 24px;
}

.user-box {
  display: flex;
  align-items: center;
}

.bot-box {
  display: flex;
  align-items: stretch;
}

.icon {
  margin-right: 30px;
}

.user-icon-wrapper {
  display: flex;
  align-items: flex-start;
}

.search-input {
  width: 90vw;
  border: 0px;
  font-weight: bold;
  margin-left: 20px;
}

.main-icon {
  margin-top: 3vh;
}

.search-icon {
  height: 32px;
  width: 32px;
  margin: 20px;
}

.document-name {
  color: #82008f;
  font-weight: bolder;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background: #823476;
  width: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #823476;
}

::-webkit-scrollbar-button {
  display: none;
}
</style>
